
body > div:first-child{
height: 100%;
}
.ant-layout{
  height: 100%;
}

body{
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 8px !important;
  height: 8px;
  border-radius: 14px;
}

::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #9a9a9a;
}